@font-face {
  font-family: 'MojFont';
  src: url('../fonts/ArealRNIDS-Regular.woff2') format('woff2');
}
body {
  margin: 0;
  font-family: 'MojFont' !important;
}
.ant-typography {
  font-family: 'MojFont' !important;
}
code {
  font-family: 'MojFont' !important;
}
.App {
  text-align: center;
  font-Family: 'MojFont' !important;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
  font-Family: 'MojFont';
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
    font-Family: 'MojFont';
  }
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  font-Family: 'MojFont';
}
.App-link {
  color: #61dafb;
  font-Family: 'MojFont';
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
    font-Family: 'MojFont';
  }
  to {
    transform: rotate(360deg);
    font-Family: 'MojFont';
  }
}
.h1 {
  font-family: 'MojFont';
  src: url('../fonts/ArealRNIDS-Regular.woff2') format('woff2');
  display: 'flex';
  color: #333;
  font-size: 30px;
  text-align: center;
  font-weight: normal;
  margin-left: 30px; 
  margin-right: 30px; 
}
.text {
  font-family: 'MojFont';
  display: 'flex';
  color: #333;
  font-size: 20px;
  text-align: center;
  margin-left: 80px; 
  margin-right: 80px; 
}
.text2 {
  font-family: 'MojFont';
  display: 'flex';
  color: #333;
  font-size: 20px;
  text-align: center;
  margin-left: 80px; 
  margin-right: 80px; 
  margin-Bottom: -35px;
}
.text3 {
  font-family: 'MojFont';
  display: 'flex';
  color: #333;
  font-size: 20px;
  text-align: center;
  margin-left: 80px; 
  margin-right: 80px; 
  margin-Bottom: -10px;
}
.text4 {
  font-family: 'MojFont';
  display: 'flex';
  font-size: 16px;
}
.text5 { 
  font-family: 'MojFont';
  display: 'flex';
  color: #333 !important;
  font-size: 20px;
  text-align: center;
  margin-left: 80px; 
  margin-right: 80px; 
  margin-Top: -50px !important;
  padding-Bottom: -50px !important;
}
@media print {
  body * {
    display: none;
  }
  .card-to-print {
    display: block !important;
    margin: 0 auto; 
    width: 100%; 
    background: white;
    box-shadow: none;
    border: none; 
  }
}
.modal-form-item .ant-form-item-explain {
  font-family: 'МојФонт';
  font-size: 16px;
}
.modal-form-item .ant-form-item-label {
  font-Family: 'MojFont';
}
.custom-form-item .ant-form-item-label {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: top;
  margin-Top: 20;
  padding-Bottom: 10;
  padding-Left: 30;
  padding-Right: 30;
  display: 'flex';
  flex-Direction: 'column';
  font-Family: 'MojFont';
}
.custom-form-item::tooltip {
  font-family: 'MojFont';
}
.login-form-button {
  font-family: 'MojFont';
  display: 'flex';
  align-items: center;
  width: 200px;
}
.login-platforma {
  font-family: 'MojFont';
  display: 'flex';
  align-items: center;
  padding-Left: 35%;
  padding-Right: 35%;
  width: 100%;
}
@media (max-width: 800px) {
  .login-platforma {
    font-family: 'MojFont';
    display: 'flex';
    align-items: center;
    padding-Left: 20%;
    padding-Right: 20%;
    width: 100%;
  }
}
.menu {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  padding: 0;
}
.line {
  fill: none;
  stroke: #333;  
  stroke-width: 6;
  stroke-linecap: round; 
  stroke-dasharray: 6000; 
  transition:
    stroke-dasharray  1000ms cubic-bezier(0.4, 0, 0.2, 1),
    stroke-dashoffset 3000ms cubic-bezier(0.4, 0, 0.2, 1);
}
.line1 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}
.line2 {
  stroke-dasharray: 60 60;
  stroke-width: 6;
}
.line3 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}
.opened .line1 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
}
.opened .line2 {
  stroke-dasharray: 1 60;
  stroke-dashoffset: -30;
  stroke-width: 6;
}
.opened .line3 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
}
.input-group {
  position: relative;
  font-family: 'MojFont';
  color: #333;
  width: 100%;
}
.input {
  font-family: 'MojFont';
  color: #333;
  background: white;
  padding-bottom: 0.75rem;
  padding-top: 0.75rem;
  align-items: center;
}
.user-label {
  position: absolute;
  left: 15px;
  color: #333;
  pointer-events: none;
  transform: translateY(1rem);
  transition: 400ms cubic-bezier(0,0,0,1);
  border-radius: 6px;
}
.input:focus, input:valid {
  outline: none;
  color: #333;
}
.input:focus ~ label, input:valid ~ label {
  transform: translateY(-50%) scale(0.8);
  background-color: white;  
  color: #333;
  border: 1px solid #d9d9d9; 
  padding-left: 6px; 
  padding-right: 6px;
}
.certain-category-search-dropdown .ant-select-dropdown-menu-item-group-title {
  color: #666;
  font-weight: bold;
}
.certain-category-search-dropdown .ant-select-dropdown-menu-item-group {
  border-bottom: 1px solid #f6f6f6;
}
.certain-category-search-dropdown .ant-select-dropdown-menu-item {
  padding-left: 16px;
}
.certain-category-search-dropdown .ant-select-dropdown-menu-item.show-all {
  text-align: center;
  cursor: default;
}
.certain-category-search-dropdown .ant-select-dropdown-menu {
  max-height: 300px;
}
#obican_input_number {
  font-family: 'MojFont'; 
}


.ant-input::placeholder {
  font-family: 'MojFont';
}
.ant-input {
  font-family: 'MojFont';
}
.ant-steps-item-title {
  font-family: 'MojFont';
}
.ant-steps-item-content {
  font-family: 'MojFont';
}
.card-to-print .ant-card-head-title {
  font-family: 'MojFont';
  text-align: center;
  font-size: 24px;
}
.kontakt .ant-card-head-title {
  font-family: 'MojFont';
  text-align: center;
  font-size: 24px;
}
.card-kontakt .ant-card-head-title {
  font-family: 'MojFont';
  text-align: center;
  font-size: 24px;
  color:#333
}
.card-kontakt {
  width: 60%;
  margin: 0 auto; 
  background-color: rgba(51, 102, 153, 0.1);
  padding-Left: 0;
  }
@media (max-width: 800px) {
  .card-kontakt {
    background-color: 'rgba(51, 102, 153, 0.1)';
    text-Align: center;
    padding-Left: 0;
      margin: 0 auto;
      width: 95%;
  }
}
.card-upit .ant-card-head-title {
  font-family: 'MojFont';
  text-align: center;
  font-size: 24px;
  color: #333
}
.card-upit {
  width: 60%;
  margin: 0 auto; 
  background-color: rgba(51, 102, 153, 0.1);

  padding-Left: 0;
}
@media (max-width: 800px) {
  .card-upit {
  width: 90%;
  margin: 0 auto; 
  background-color: rgba(51, 102, 153, 0.1);
  padding-Left: 0;
  }
}
@media (min-width: 800px) {
  .kontakt1 {
    display: none; 
  }
}
@media (max-width: 800px) {
  .kontakt2 {
    display: none; 
  }
}
.my-select {
  font-family: 'MojFont';
}
.my-select .ant-select-selector {
  font-family: 'MojFont';
}
.ant-form-item-explain {
  font-family: 'MojFont';
  justify-content: center;
}